import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout";
// import Seo from "../components/seo"
import Banner from "../components/Banner";
import Categories from "../components/Categories";
import ProductList from "../components/ProductList";
import SupportSection from "../components/SupportSection";
import { useAllProductsData } from '../helpers';

const topTrending = [
  {
    id: 0,
    image: 'https://images.unsplash.com/photo-1604508230015-5a54faf1fa56?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80',
    title: 'Designer T-Shirts',
    price: '400'
  }, {
    id: 1,
    image: 'https://images.unsplash.com/photo-1602810319250-a663f0af2f75?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80',
    title: 'Cluse La Boheme Rose Gold',
    price: '500'
  }, {
    id: 2,
    image: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1868&q=80',
    title: 'Black Shirts',
    price: '300'
  }, {
    id: 3,
    image: 'https://images.unsplash.com/photo-1602810318660-d2c46b750f88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1868&q=80',
    title: 'Nimble Made',
    price: '800'
  }, {
    id: 4,
    image: 'https://images.unsplash.com/photo-1602810316481-0d5d7401737a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1868&q=80',
    title: 'Designer Tie Pins',
    price: '1200'
  }, {
    id: 5,
    image: 'https://images.unsplash.com/photo-1615791966365-92f425a37a0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80',
    title: 'Designer Jeans',
    price: '1000'
  }, {
    id: 6,
    image: 'https://images.unsplash.com/photo-1551794840-8ae3b9c181f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80',
    title: 'Premium Leather Jacket',
    price: '2000'
  }, {
    id: 7,
    image: 'https://images.unsplash.com/photo-1600269452121-4f2416e55c28?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1901&q=80',
    title: 'Nike Sneakers',
    price: '500'
  },
]

const IndexPage = () => {
  const [bannerData, setBannerData] = React.useState([]);
  const [homeProductsData, setHomeProductsData] = React.useState([]);
  const [homeLoading, setHomeLoading] = React.useState(false);
  const allProducts = useAllProductsData();

  React.useEffect(() => {
    if (allProducts?.homeDetails) {
      const _bannerData = allProducts?.homeDetails?.Banner;
      const _homeProductListData = allProducts?.homeDetails?.Products;
      const _homeLoading = allProducts?.homeDetailsLoading;
      setBannerData(_bannerData);
      setHomeProductsData(_homeProductListData);
      setHomeLoading(_homeLoading);
    }
  }, [allProducts])
  console.log('_homeLoading', homeLoading)
  return (
    <Layout loading={homeLoading}>
      {/* <Seo title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
      <Banner bannerData={bannerData} />
      <Categories />
      <ProductList data={homeProductsData} title='Trending' subTitle='Top view in this week' />
      <SupportSection />
    </Layout>
  )

}

export default IndexPage
